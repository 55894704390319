// @ts-strict-ignore
import { InspectionCommentDTO } from '@library/dto/inspection/inspection-comment.dto';
import { PerProductAqlAndSamplingDTO } from '@library/dto/per-product-aql-and-sampling.dto';
import { DefectFoundDTO } from '@library/dto/testschecklist/answer/defect-found.dto';
import { TestsChecklistAnswerElementImageDTO } from '@library/dto/testschecklist/answer/tests-checklist-answer-element-image.dto';
import { DefectClassification } from '@library/dto-enums/defect-classification.dto-enum';
import { qpIsFiniteNumber } from '@library/functions/checks/qp-is-finite-number';
import { qpIsNotNil } from '@library/functions/checks/qp-is-not-nil';
import { qpParseInt } from '@library/functions/math/qp-parse-int';
import { EQpImageElementType } from '@library/models/qp-image.models';
import { EQpQuestionType } from '@library/models/qp-question.models';
import {
  AverageNumberValue,
  IQpTestsChecklistElement,
  IQpTestsChecklistElementTableRows,
  IQpTestsChecklistElementTableValue,
  QpTestsChecklistElementTableColumnType,
  QpTestsChecklistElementType,
} from '@library/models/qp-tests-checklist-element.models';
import {
  IDefectsCategories,
  IDefectsChecklistDefect,
} from '@one/app/pages/isp/pages/inspection/pages/id/pages/defects-checklist/isp-inspection-id-defect.models';
import { ISP_WORKFLOW_STEP_ACTION_STRATEGY } from '@one/app/pages/isp/pages/inspection/pages/id/shared/models/isp-create-workflow-step-action.models';
import { IspMeasuresSampleAnswer } from '@one/app/pages/isp/shared/classes/isp-measures-sample-answer/isp-measures-sample-answer';
import { IspVariance } from '@one/app/pages/isp/shared/classes/isp-variance';
import {
  IMeasure,
  IMeasurementProduct,
  IMeasuresChecklist,
  IMeasuresChecklistSection,
  IMeasuresSampleAnswer,
  IProductVariances,
  IVariance,
} from '@one/app/pages/isp/shared/models/variances/inspection-variances.models';
import { InspectionLevelSamplingSize } from '@one/app/shared/classes/inspection/inspection-level-sampling-size';
import { MeasuresChecklistActionSection } from '@one/app/shared/classes/measurements/measures-checklist-action-section';
import { PercentageSampleSize } from '@one/app/shared/classes/sampling/percentage-sample-size';
import { QuantitySampleSize } from '@one/app/shared/classes/sampling/quantity-sample-size';
import { CUSTOM_QIMA_DEFECT_CHECKLIST } from '@one/app/shared/constants/custom-qima-defect-checklist';
import { Answer } from '@one/app/shared/models/answer/answer.models';
import { YesNoAnswerModel } from '@one/app/shared/models/answer/yes-no-answer.models';
import { EChecklistRequirementType } from '@one/app/shared/models/checklist/checklist-requirement.models';
import { AqlDefectsType } from '@one/app/shared/models/defects/aql-defects.models';
import {
  IAttachmentData,
  IChecklistImageData,
  IInspectionConsultation,
} from '@one/app/shared/models/inspection-consultation/inspection-consultation.models';
import {
  ESamplingSizeType,
  ISamplingSizeProduct,
  IWorkflowSamplingSize,
  WorkflowSamplingSizeType,
} from '@one/app/shared/models/sampling/sampling-size.models';
import {
  EWorkflowActionType,
  EWorkflowPath,
  INewDefectInfo,
  IProductActualQuantities,
  IUpdateDefectData,
  IWorkflow,
  IWorkflowAction,
  IWorkflowStep,
  WorkflowAuditCheckType,
  WorkflowCheckType,
} from '@one/app/shared/models/workflow/workflow.models';
import { WorkflowPathIdType } from '@one/app/shared/models/workflows/workflow.models';
import { EWorkflowMeasurementProof, EWorkflowTemplateStepName } from '@one/app/shared/models/workflows/workflows-templates.models';
import { ProductMeasurementsService } from '@one/app/shared/services/measurements/product-measurements.service';
import { SamplingSizeService } from '@one/app/shared/services/sampling-size/sampling-size.service';
import { QimaOptionalType } from '@qima/ngx-qima';
import { cloneDeep, has, isEmpty, isNil, remove } from 'lodash/index';

/**
 * @deprecated
 * Use instead the {@link IspWorkflow}
 * Please use the sub-classes as well
 * Respect the OOP!
 *
 * The goal is to avoid having all the methods in the root of the workflow
 */
export default class WorkflowUtils {
  public static getPath(path: string): number[] {
    return path.split('-').map((i): number => Number(i) - 1);
  }

  /**
   * Be cautious: in case multiple actions match the provided type only the first one found will be returned.
   *
   * @param workflow
   * @param actionType
   */
  public static getWorkflowActionWithType(workflow: IWorkflow, actionType: EWorkflowActionType): IWorkflowAction | undefined {
    return this.getWorkflowActionsWithType(workflow, actionType).find((action: IWorkflowAction): boolean => action.type === actionType);
  }

  /**
   * @param {IWorkflow} workflow
   * @returns {IWorkflow}
   */
  public static addSectionToWorkflowMeasurementChecklist(workflow: IWorkflow): IWorkflow {
    const workflowStep: IWorkflowStep[] = workflow.steps.map((step: IWorkflowStep): IWorkflowStep => {
      const stepActions: IWorkflowAction[] = step.actions.map((action: IWorkflowAction): IWorkflowAction => {
        if (action.type === EWorkflowActionType.MEASURES_CHECKLIST && !isEmpty(action.measuresChecklists)) {
          const measuresChecklists: IProductVariances[] = action.measuresChecklists.map(
            (checklist: IProductVariances): IProductVariances => new MeasuresChecklistActionSection(checklist)
          );

          return { ...action, measuresChecklists };
        }

        return action;
      });

      return {
        ...step,
        actions: stepActions,
      };
    });

    return {
      ...workflow,
      steps: workflowStep,
    };
  }

  /**
   * @description
   * Check if {@link IMeasurementProduct} has sections
   * @param {IMeasurementProduct} productMeasure
   * @returns {boolean}
   */
  public static doesWorkflowProductMeasureHasSections(productMeasure: IMeasurementProduct | IProductVariances): boolean {
    if (isEmpty(productMeasure.sections)) {
      return false;
    }

    return productMeasure.sections.some((measureModel: IMeasuresChecklistSection | IMeasuresChecklist): boolean =>
      has(measureModel, 'name')
    );
  }

  public static getMeasuresEntriesNextIndex(measuresEntries: IMeasuresSampleAnswer[]): number {
    const lastMeasureEntry: QimaOptionalType<IMeasuresSampleAnswer> = measuresEntries?.[measuresEntries.length - 1];
    const measureAnswer: QimaOptionalType<IMeasure> = lastMeasureEntry?.measureAnswers?.[0];
    const measureIdentifier: QimaOptionalType<string> = measureAnswer?.id;
    const pathIds: QimaOptionalType<string[]> = measureIdentifier?.split('-');
    const lastMeasureId: QimaOptionalType<number> = qpParseInt(pathIds?.[4]);

    return (lastMeasureId || measuresEntries.length) + 1;
  }

  /**
   * Update workflow sampling size & tests sampling sizes
   *
   * @param workflow
   * @param samplingSize
   */
  public static updateWorkflowSamplingSize(workflow: IWorkflow, samplingSize: WorkflowSamplingSizeType): IWorkflow {
    const clonedWorkflow: IWorkflow = cloneDeep(workflow);

    if (!isNil(workflow)) {
      clonedWorkflow.samplingSize = {
        ...samplingSize,
        aqlModificationReason:
          !isNil(workflow.samplingSize) && 'aqlModificationReason' in workflow.samplingSize
            ? (workflow.samplingSize?.aqlModificationReason ?? '')
            : '',
      };
    }

    const lotSize = clonedWorkflow.samplingSize.products.reduce(
      (acc: number, curr: ISamplingSizeProduct): number => acc + (qpIsFiniteNumber(curr.lotSize) ? curr.lotSize : 0),
      0
    );

    return this.updateTestsSamplingSize(clonedWorkflow, lotSize);
  }

  /**
   * Update only workflow tests sampling sizes
   *
   * @param workflow
   * @param lotSize
   */
  public static updateTestsSamplingSize(workflow: IWorkflow, lotSize: number): IWorkflow {
    const clonedWorkflow: IWorkflow = cloneDeep(workflow);

    for (const step of clonedWorkflow.steps[1].actions) {
      if (step.type === EWorkflowActionType.TESTS_CHECKLIST) {
        for (const testCategory of step.testsChecklist.content.elements) {
          for (const test of testCategory.elements) {
            if (!isNil(lotSize) && test.samplingSize && test.samplingSize.type === ESamplingSizeType.INSPECTION_LEVEL_SAMPLE_SIZE) {
              const testSamplingSize = SamplingSizeService.getSamplingSize(
                (test.samplingSize as unknown as InspectionLevelSamplingSize).inspectionLevel,
                lotSize
              );

              test.samplingSize.sampleSize = testSamplingSize.getRight();
            } else if (test.samplingSize && test.samplingSize.type === ESamplingSizeType.PERCENTAGE_SAMPLE_SIZE) {
              test.samplingSize.sampleSize = Math.ceil(((test.samplingSize as unknown as PercentageSampleSize).percentage * lotSize) / 100);
            } else if (test.samplingSize && test.samplingSize.type === ESamplingSizeType.QUANTITY_SAMPLE_SIZE) {
              test.samplingSize.sampleSize = (test.samplingSize as unknown as QuantitySampleSize).quantity;
            }
          }
        }
      }
    }

    return clonedWorkflow;
  }

  public static updateProductActualQuantities(workflow: IWorkflow, productActualQuantities: IProductActualQuantities): IWorkflow {
    const clonedWorkflow: IWorkflow = cloneDeep(workflow);
    const inspectionPreparationStep = clonedWorkflow.steps
      .find((step): boolean => step.name === EWorkflowTemplateStepName.DURING_INSPECTION)
      .actions.find((action): boolean => action.type === EWorkflowActionType.INSPECTION_PREPARATION);

    if (inspectionPreparationStep) {
      const index = inspectionPreparationStep.productQuantities.findIndex(
        (value): boolean =>
          value.productId === productActualQuantities.productId &&
          (!productActualQuantities.purchaseOrderProductId ||
            value.purchaseOrderProductId === productActualQuantities.purchaseOrderProductId)
      );

      if (index === -1) {
        inspectionPreparationStep.productQuantities.push(productActualQuantities);
      } else {
        inspectionPreparationStep.productQuantities[index] = productActualQuantities;
      }
    }

    return clonedWorkflow;
  }

  public static updateSiteLocation(workflow: IWorkflow, entityId: number): IWorkflow {
    const clonedWorkflow: IWorkflow = cloneDeep(workflow);

    if (!isNil(clonedWorkflow)) {
      clonedWorkflow.steps = clonedWorkflow.steps.map((step: IWorkflowStep): IWorkflowStep => {
        const updatedActions: IWorkflowAction[] = step.actions.map((action: IWorkflowAction): IWorkflowAction => {
          if (action.type === EWorkflowActionType.SITE_LOCATION) {
            return {
              ...action,
              entity: { ...action.entity, id: entityId },
            };
          }

          return action;
        });

        return {
          ...step,
          actions: updatedActions,
        };
      });
    }

    return clonedWorkflow;
  }

  public static updateFindingSummaryReviewPendingState(workflow: IWorkflow, isInspectionMarkedAsPending: boolean): IWorkflow {
    const clonedWorkflowSteps: IWorkflowStep[] = cloneDeep(workflow.steps).map(
      (step: IWorkflowStep): IWorkflowStep => ({
        ...step,
        actions: step.actions.map((action: IWorkflowAction): IWorkflowAction => {
          if (action.type === EWorkflowActionType.FINDINGS_SUMMARY_REVIEW) {
            return {
              ...action,
              isInspectionMarkedAsPending,
            };
          }

          return action;
        }),
      })
    );

    return {
      ...workflow,
      steps: clonedWorkflowSteps,
    };
  }

  /**
   * @description
   * @todo
   * Sorry again
   * We need a better {@link IspWorkflow} so that there is an easy editable mode
   * @param {IWorkflow} workflow
   * @param {WorkflowPathIdType} elementPathId
   * @param {string} imageId
   * @param {IChecklistImageData} imageData If parentImage is provided here, will replace the image
   * @returns {IWorkflow}
   */
  public static addTestPicture(
    workflow: Readonly<IWorkflow>,
    elementPathId: Readonly<WorkflowPathIdType>,
    imageId: Readonly<string>,
    imageData: Readonly<IChecklistImageData>
  ): IWorkflow {
    const clonedWorkflow: IWorkflow = cloneDeep(workflow);
    const path: number[] = elementPathId.split('-').map((i): number => Number(i) - 1);
    const action: IWorkflowAction = this.getActionFromPath(clonedWorkflow, elementPathId);
    const div = action.testsChecklist.content.elements[path[2]];
    const testElement: IQpTestsChecklistElement<Answer> = div.elements[path[3]];
    const imageToAdd: IChecklistImageData = { ...imageData, imageId };

    if (testElement.images && imageData.parentImage) {
      const existingImageIndex: number = testElement.images.findIndex((image): boolean => image.imageId === imageData.parentImage);

      testElement.images[existingImageIndex] = imageToAdd;
    } else {
      testElement.images.push(imageToAdd);
    }

    return clonedWorkflow;
  }

  public static reorderTestsPictures(
    workflow: Readonly<IWorkflow>,
    elementPathId: Readonly<WorkflowPathIdType>,
    imageIds: Readonly<string[]>
  ): IWorkflow {
    const clonedWorkflow: IWorkflow = cloneDeep(workflow);
    const path: number[] = elementPathId.split('-').map((i): number => Number(i) - 1);
    const action: IWorkflowAction = this.getActionFromPath(clonedWorkflow, elementPathId);
    const div = action.testsChecklist.content.elements[path[2]];
    const testElement: IQpTestsChecklistElement<Answer> = div.elements[path[3]];

    testElement.images = this.reorderPictures(testElement.images, imageIds);

    return clonedWorkflow;
  }

  /**
   * @description
   * @param {IWorkflow} workflow
   * @param {WorkflowPathIdType} elementPathId
   * @param {string} imageId
   * @param attachmentData
   * @param {IChecklistImageData} imageData
   * @returns {IWorkflow}
   */
  public static addTestsAttachment(
    workflow: Readonly<IWorkflow>,
    elementPathId: Readonly<WorkflowPathIdType>,
    attachmentData: Readonly<IAttachmentData>
  ): IWorkflow {
    const clonedWorkflow: IWorkflow = cloneDeep(workflow);
    const path: number[] = elementPathId.split('-').map((i): number => Number(i) - 1);
    const action: IWorkflowAction = this.getActionFromPath(clonedWorkflow, elementPathId);
    const div = action.testsChecklist.content.elements[path[2]];
    const testElement: IQpTestsChecklistElement<Answer> = div.elements[path[3]];

    testElement.attachments.push({ ...attachmentData });

    return clonedWorkflow;
  }

  public static addSimplifiedMeasurementAttachment(workflow: Readonly<IWorkflow>, attachmentData: Readonly<IAttachmentData>): IWorkflow {
    const clonedWorkflow: IWorkflow = cloneDeep(workflow);
    let workflowAction: IWorkflowAction;

    for (const step of clonedWorkflow.steps) {
      for (const action of step.actions) {
        if (action.type === EWorkflowActionType.SIMPLIFIED_MEASUREMENTS_CHECKLIST) {
          workflowAction = action;
        }
      }
    }

    workflowAction.simplifiedMeasurement?.attachments.push({ ...attachmentData });

    return clonedWorkflow;
  }

  /**
   * @description
   * @todo
   * Sorry again
   * We need a better {@link IspWorkflow} so that there is an easy editable mode
   * @param {IWorkflow} workflow
   * @param {WorkflowPathIdType} elementPathId
   * @param {string} imageId
   * @param {IChecklistImageData} imageData If parentImage is provided here, will replace the image
   * @returns {IWorkflow}
   */
  public static addTestScan(
    workflow: Readonly<IWorkflow>,
    elementPathId: Readonly<WorkflowPathIdType>,
    imageId: Readonly<string>,
    imageData: Readonly<IChecklistImageData>
  ): IWorkflow {
    const clonedWorkflow: IWorkflow = cloneDeep(workflow);
    const path: number[] = elementPathId.split('-').map((i): number => Number(i) - 1);
    const action: IWorkflowAction = this.getActionFromPath(clonedWorkflow, elementPathId);
    const div = action.testsChecklist.content.elements[path[2]];
    const testElement: IQpTestsChecklistElement<Answer> = div.elements[path[3]];
    const imageToAdd: IChecklistImageData = { ...imageData, imageId };

    if (testElement.scans && imageData.parentImage) {
      const existingScanIndex: number = testElement.scans.findIndex(
        (image: IChecklistImageData): boolean => image.imageId === imageData.parentImage
      );

      testElement.scans[existingScanIndex] = imageToAdd;
    } else {
      testElement.scans.push(imageToAdd);
    }

    return clonedWorkflow;
  }

  /**
   * @description
   * @todo
   * Sorry again
   * We need a better {@link IspWorkflow} so that there is an easy editable mode
   * @param {IWorkflow} workflow
   * @param {WorkflowPathIdType} elementPathId
   * @param {string} imageId
   * @param {IChecklistImageData} imageData If parentImage is provided here, will replace the image
   * @param {Readonly<number>} productId Product to which the defect is linked
   * @param {Readonly<number>} purchaseOrderProductId Purchase Order Product to which the defect is linked
   * @returns {IWorkflow}
   */
  public static addDefectsPicture(
    workflow: Readonly<IWorkflow>,
    elementPathId: Readonly<WorkflowPathIdType>,
    imageId: Readonly<string>,
    imageData: Readonly<IChecklistImageData>
  ): IWorkflow {
    const clonedWorkflow: IWorkflow = cloneDeep(workflow);
    const path: number[] = elementPathId.split('-').map((i): number => Number(i) - 1);
    const action: IWorkflowAction = this.getActionFromPath(clonedWorkflow, elementPathId);
    const category = action.defectsChecklist.categories[path[2]];
    const defect: IDefectsChecklistDefect = category.defects[path[3]];
    let defectValue = defect.defectsFound?.find(
      (defectFound): boolean => !imageData.purchaseOrderProductId || defectFound.purchaseOrderProductId === imageData.purchaseOrderProductId
    );

    // If no defect has been specified by the inspector yet, we need to define a new one so the image can be attached properly
    if (isNil(defectValue)) {
      const newDefectValue: DefectFoundDTO = {
        images: [],
        quantity: 0,
        purchaseOrderProductId: imageData.purchaseOrderProductId,
      };

      defect.defectsFound.push(newDefectValue);
      defectValue = newDefectValue;
    }

    const imageToAdd: TestsChecklistAnswerElementImageDTO = {
      ...imageData,
      imageId,
    } as unknown as TestsChecklistAnswerElementImageDTO;

    if (defectValue?.images && imageData.parentImage) {
      const existingImageIndex: number = defectValue.images.findIndex((image): boolean => image.imageId === imageData.parentImage);

      defectValue.images[existingImageIndex] = imageToAdd;
    } else {
      defectValue?.images.push(imageToAdd);
    }

    return clonedWorkflow;
  }

  public static addSimplifiedMeasurementPicture(
    workflow: Readonly<IWorkflow>,
    elementPathId: Readonly<WorkflowPathIdType>,
    imageId: Readonly<string>,
    imageData: Readonly<IChecklistImageData>
  ): IWorkflow {
    const clonedWorkflow: IWorkflow = cloneDeep(workflow);
    const action: IWorkflowAction = this.getActionFromPath(clonedWorkflow, elementPathId);
    const imageToAdd: IChecklistImageData = { ...imageData, imageId };

    if (action.simplifiedMeasurement?.images && imageData.parentImage) {
      const existingImageIndex: number = action.simplifiedMeasurement.images.findIndex(
        (image): boolean => image.imageId === imageData.parentImage
      );

      action.simplifiedMeasurement.images[existingImageIndex] = imageToAdd;
    } else {
      action.simplifiedMeasurement.images.push(imageToAdd);
    }

    return clonedWorkflow;
  }

  public static addStartingPicture(
    workflow: Readonly<IWorkflow>,
    elementPathId: Readonly<WorkflowPathIdType>,
    imageId: Readonly<string>,
    imageData: Readonly<IChecklistImageData>
  ): IWorkflow {
    const clonedWorkflow: IWorkflow = cloneDeep(workflow);
    const action: IWorkflowAction = this.getActionFromPath(clonedWorkflow, elementPathId);
    const imageToAdd: IChecklistImageData = { ...imageData, imageId };

    if (action.pictures && imageData.parentImage) {
      const existingImageIndex: number = action.pictures.findIndex((image): boolean => image.imageId === imageData.parentImage);

      action.pictures[existingImageIndex] = imageToAdd;
    } else {
      action.pictures.push(imageToAdd);
    }

    return clonedWorkflow;
  }

  /**
   * @description
   * @todo
   * Sorry again
   * We need a better {@link IspWorkflow} so that there is an easy editable mode
   * @param {IWorkflow} workflow
   * @param {string} imageId
   * @param {IChecklistImageData} imageData If parentImage is provided here, will replace the image
   * @returns {IWorkflow}
   */
  public static addGlobalPicture(
    workflow: Readonly<IWorkflow>,
    imageId: Readonly<string>,
    imageData: Readonly<IChecklistImageData>
  ): IWorkflow {
    const clonedWorkflow: IWorkflow = cloneDeep(workflow);
    const imageToAdd: IChecklistImageData = { ...imageData, imageId };

    if (clonedWorkflow.globalImages && imageData.parentImage) {
      const existingImageIndex: number = clonedWorkflow.globalImages.findIndex((image): boolean => image.imageId === imageData.parentImage);

      clonedWorkflow.globalImages[existingImageIndex] = imageToAdd;
    } else {
      clonedWorkflow.globalImages.push(imageToAdd);
    }

    return clonedWorkflow;
  }

  public static reorderGlobalPictures(workflow: Readonly<IWorkflow>, imageIds: Readonly<string[]>): IWorkflow {
    const clonedWorkflow: IWorkflow = cloneDeep(workflow);

    clonedWorkflow.globalImages = this.reorderPictures(clonedWorkflow.globalImages, imageIds);

    return clonedWorkflow;
  }

  public static reorderDefectsPictures(
    workflow: Readonly<IWorkflow>,
    elementPathId: Readonly<WorkflowPathIdType>,
    imageIds: Readonly<string[]>,
    purchaseOrderProductId?: Readonly<number>
  ): IWorkflow {
    const clonedWorkflow: IWorkflow = cloneDeep(workflow);
    const path: number[] = elementPathId.split('-').map((i): number => Number(i) - 1);
    const action: IWorkflowAction = this.getActionFromPath(clonedWorkflow, elementPathId);
    const category = action.defectsChecklist.categories[path[2]];
    const defect: IDefectsChecklistDefect = category.defects[path[3]];
    const defectValue = defect.defectsFound?.find(
      (defectFound): boolean => !purchaseOrderProductId || defectFound.purchaseOrderProductId === purchaseOrderProductId
    );

    if (defectValue?.images) {
      defectValue.images = this.reorderPictures(
        defectValue.images as unknown as IChecklistImageData[],
        imageIds
      ) as unknown as TestsChecklistAnswerElementImageDTO[];
    }

    return clonedWorkflow;
  }

  public static reorderSimplifiedMeasurementPictures(
    workflow: Readonly<IWorkflow>,
    elementPathId: Readonly<WorkflowPathIdType>,
    imageIds: Readonly<string[]>
  ): IWorkflow {
    const clonedWorkflow: IWorkflow = cloneDeep(workflow);
    const action: IWorkflowAction = this.getActionFromPath(clonedWorkflow, elementPathId);

    if (action.simplifiedMeasurement?.images) {
      action.simplifiedMeasurement.images = this.reorderPictures(action.simplifiedMeasurement.images, imageIds);
    }

    return clonedWorkflow;
  }

  public static updatePicturesData(
    workflow: Readonly<IWorkflow>,
    elementType: QimaOptionalType<Readonly<EQpImageElementType>>,
    elementPathId: QimaOptionalType<Readonly<WorkflowPathIdType>>,
    imageIds: Readonly<string[]>,
    imageData: Readonly<Partial<IChecklistImageData>>
  ): IWorkflow {
    const clonedWorkflow: IWorkflow = cloneDeep(workflow);
    let imagesToEdit = [];

    // When there is no elementType it means we update a global picture
    if (isNil(elementType)) {
      imagesToEdit = clonedWorkflow.globalImages.filter((image): boolean => imageIds.includes(image.imageId));
    } else {
      const path: number[] = elementPathId.split('-').map((i): number => Number(i) - 1);
      const action: IWorkflowAction = this.getActionFromPath(clonedWorkflow, elementPathId);

      if (elementType === EQpImageElementType.ANSWERS) {
        const div = action.testsChecklist.content.elements[path[2]];
        const testElement: IQpTestsChecklistElement<Answer> = div.elements[path[3]];

        imagesToEdit = testElement.images.filter((image): boolean => imageIds.includes(image.imageId));
      } else if (elementType === EQpImageElementType.DEFECTS) {
        const category = action.defectsChecklist.categories[path[2]];
        const defect: IDefectsChecklistDefect = category.defects[path[3]];

        imagesToEdit =
          defect.defectsFound
            ?.flatMap((defectFound): TestsChecklistAnswerElementImageDTO[] => defectFound.images)
            .filter((image): boolean => imageIds.includes(image.imageId)) ?? [];
      } else if (elementType === EQpImageElementType.SIMPLIFIED_MEASUREMENTS) {
        imagesToEdit = action.simplifiedMeasurement.images.filter((image): boolean => imageIds.includes(image.imageId));
      } else if (elementType === EQpImageElementType.STARTING) {
        imagesToEdit = action.pictures.filter((image): boolean => imageIds.includes(image.imageId));
      }
    }

    imagesToEdit.forEach((_, index): void => {
      if (!isNil(imageData.caption)) {
        imagesToEdit[index].caption = imageData.caption;
      }

      if (!isNil(imageData.isSpotlight)) {
        imagesToEdit[index].isSpotlight = imageData.isSpotlight;
      }

      if (!isNil(imageData.pictureTags)) {
        imagesToEdit[index].pictureTags = imageData.pictureTags;
      }

      if (!isNil(imageData.isLinkedToTestResult)) {
        imagesToEdit[index].isLinkedToTestResult = imageData.isLinkedToTestResult;
      }
    });

    return clonedWorkflow;
  }

  /**
   * @description
   * @todo
   * Sorry
   * We need a better {@link IspWorkflow} so that there is an easy editable mode
   * @param {IWorkflow} workflow
   * @param {WorkflowPathIdType} elementPathId
   * @param {string} imageId
   * @returns {IWorkflow}
   */
  public static deleteTestsPicture(
    workflow: Readonly<IWorkflow>,
    elementPathId: Readonly<WorkflowPathIdType>,
    imageId: Readonly<string>
  ): IWorkflow {
    const clonedWorkflow: IWorkflow = cloneDeep(workflow);
    const path: number[] = elementPathId.split('-').map((i): number => Number(i) - 1);
    const action: IWorkflowAction = this.getActionFromPath(clonedWorkflow, elementPathId);
    const div = action.testsChecklist.content.elements[path[2]];
    const testElement: IQpTestsChecklistElement<Answer> = div.elements[path[3]];

    remove(testElement.images, (image: IChecklistImageData): boolean => image?.imageId === imageId);

    return clonedWorkflow;
  }

  /**
   * @param workflow
   * @param elementPathId
   * @param imageId
   * @param purchaseOrderProductId
   * @description
   * @todo
   * Sorry
   * We need a better {@link IspWorkflow} so that there is an easy editable mode
   */
  public static deleteDefectsPicture(
    workflow: Readonly<IWorkflow>,
    elementPathId: Readonly<WorkflowPathIdType>,
    imageId: Readonly<string>
  ): IWorkflow {
    const clonedWorkflow: IWorkflow = cloneDeep(workflow);
    const path: number[] = elementPathId.split('-').map((i): number => Number(i) - 1);
    const action: IWorkflowAction = this.getActionFromPath(clonedWorkflow, elementPathId);
    const category = action.defectsChecklist.categories[path[2]];
    const defect: IDefectsChecklistDefect = category.defects[path[3]];
    const defectValue = defect.defectsFound?.find((defectFound): boolean =>
      defectFound.images.some((image): boolean => image.imageId === imageId)
    );

    if (defectValue) {
      remove(defectValue.images, (image: TestsChecklistAnswerElementImageDTO): boolean => image?.imageId === imageId);
    }

    return clonedWorkflow;
  }

  public static deleteSimplifiedMeasurementPicture(
    workflow: Readonly<IWorkflow>,
    elementPathId: Readonly<WorkflowPathIdType>,
    imageId: Readonly<string>
  ): IWorkflow {
    const clonedWorkflow: IWorkflow = cloneDeep(workflow);
    const action: IWorkflowAction = this.getActionFromPath(clonedWorkflow, elementPathId);

    if (action.simplifiedMeasurement?.images) {
      remove(action.simplifiedMeasurement.images, (image: IChecklistImageData): boolean => image?.imageId === imageId);
    }

    return clonedWorkflow;
  }

  public static deleteStartingPicture(
    workflow: Readonly<IWorkflow>,
    elementPathId: Readonly<WorkflowPathIdType>,
    imageId: Readonly<string>
  ): IWorkflow {
    const clonedWorkflow: IWorkflow = cloneDeep(workflow);
    const action: IWorkflowAction = this.getActionFromPath(clonedWorkflow, elementPathId);

    if (action.pictures) {
      remove(action.pictures, (image: IChecklistImageData): boolean => image?.imageId === imageId);
    }

    return clonedWorkflow;
  }

  public static deleteTestScan(
    workflow: Readonly<IWorkflow>,
    elementPathId: Readonly<WorkflowPathIdType>,
    imageId: Readonly<string>
  ): IWorkflow {
    const clonedWorkflow: IWorkflow = cloneDeep(workflow);
    const path: number[] = elementPathId.split('-').map((i): number => Number(i) - 1);
    const action: IWorkflowAction = this.getActionFromPath(clonedWorkflow, elementPathId);
    const div = action.testsChecklist.content.elements[path[2]];
    const testElement: IQpTestsChecklistElement<Answer> = div.elements[path[3]];

    remove(testElement.scans, (image: IChecklistImageData): boolean => image?.imageId === imageId);

    return clonedWorkflow;
  }

  public static setTestScanCaption(
    workflow: Readonly<IWorkflow>,
    elementPathId: Readonly<WorkflowPathIdType>,
    imageId: Readonly<string>,
    caption: Readonly<string>
  ): IWorkflow {
    const clonedWorkflow: IWorkflow = cloneDeep(workflow);
    const path: number[] = elementPathId.split('-').map((i): number => Number(i) - 1);
    const action: IWorkflowAction = this.getActionFromPath(clonedWorkflow, elementPathId);
    const div = action.testsChecklist.content.elements[path[2]];
    const testElement: IQpTestsChecklistElement<Answer> = div.elements[path[3]];
    const existingScanIndex: number = testElement.scans?.findIndex((image: IChecklistImageData): boolean => image.imageId === imageId);

    if (!isNil(existingScanIndex)) {
      testElement.scans[existingScanIndex].caption = caption;
    }

    return clonedWorkflow;
  }

  public static deleteTestAttachment(
    workflow: Readonly<IWorkflow>,
    elementPathId: Readonly<WorkflowPathIdType>,
    attachmentId: Readonly<string>
  ): IWorkflow {
    const clonedWorkflow: IWorkflow = cloneDeep(workflow);
    const path: number[] = elementPathId.split('-').map((i): number => Number(i) - 1);
    const action: IWorkflowAction = this.getActionFromPath(clonedWorkflow, elementPathId);
    const div = action.testsChecklist.content.elements[path[2]];
    const testElement: IQpTestsChecklistElement<Answer> = div.elements[path[3]];

    remove(testElement.attachments, (attachment: IAttachmentData): boolean => attachment?.attachmentId === attachmentId);

    return clonedWorkflow;
  }

  public static deleteSimplifiedMeasurementAttachment(workflow: Readonly<IWorkflow>, attachmentId: Readonly<string>): IWorkflow {
    const clonedWorkflow: IWorkflow = cloneDeep(workflow);
    let workflowAction: IWorkflowAction;

    for (const step of clonedWorkflow.steps) {
      for (const action of step.actions) {
        if (action.type === EWorkflowActionType.SIMPLIFIED_MEASUREMENTS_CHECKLIST) {
          workflowAction = action;
        }
      }
    }

    remove(
      workflowAction.simplifiedMeasurement.attachments,
      (attachment: IAttachmentData): boolean => attachment.attachmentId === attachmentId
    );

    return clonedWorkflow;
  }

  public static replaceAWorkflowAction(workflow: IWorkflow, action: IWorkflowAction): IWorkflow {
    return {
      ...workflow,
      steps: workflow.steps.map((step: IWorkflowStep): IWorkflowStep => {
        return {
          ...step,
          actions: step.actions.map((workflowAction: IWorkflowAction): IWorkflowAction => {
            return workflowAction.id === action.id ? action : workflowAction;
          }),
        };
      }),
    };
  }

  public static getActionFromPath(workflow: IWorkflow, fullPath: string): IWorkflowAction {
    if (!fullPath) {
      throw new Error('action.path.mandatory');
    }

    const path = this.getPath(fullPath);

    if (path.length < EWorkflowPath.MIN_PATH_LENGTH) {
      throw new Error('action.path.incorrect');
    }

    const step = workflow.steps[path[EWorkflowPath.STEP]];
    const action = step?.actions[path[EWorkflowPath.ACTION]];

    if (action) {
      return ISP_WORKFLOW_STEP_ACTION_STRATEGY[action.type]?.(action) ?? action;
    }

    throw new Error('action.not.found');
  }

  public static getProductVariancesByProductVariance(productVariances: IProductVariances[], path: string): IVariance[] | undefined {
    return productVariances.find((productVariance: IProductVariances): boolean => productVariance.id === path)?.variances;
  }

  // Test checklist 🧪

  public static getTestById(workflow: IWorkflow, testId: string): QimaOptionalType<IQpTestsChecklistElement<Answer>> {
    let testElement: QimaOptionalType<IQpTestsChecklistElement<Answer>> = null;

    if (testId && workflow) {
      const path: number[] = testId.split('-').map((i): number => Number(i) - 1);
      const step: IWorkflowStep = workflow.steps[path[0]];
      const action: IWorkflowAction = step.actions[path[1]];
      const div = action.testsChecklist.content.elements[path[2]];

      testElement = div.elements[path[3]];
    }

    return testElement;
  }

  public static isLastTest(workflow: IWorkflow, categoryPath: string, testPath: string): boolean {
    const action: IWorkflowAction = this.getActionFromPath(workflow, categoryPath);
    let result: boolean;

    switch (action.type) {
      case EWorkflowActionType.TESTS_CHECKLIST:
        result = this.isLastTestsChecklistTest(action.testsChecklist.content.elements, this.getPath(testPath));
        break;
      case EWorkflowActionType.MEASURES_CHECKLIST:
        result = this.isLastMeasurementChecklist(action.measuresChecklists[0].variances, this.getPath(testPath));
        break;
      default:
        result = false;
        break;
    }

    return result;
  }

  public static isTestFulfilled(workflow: IWorkflow, categoryPath: string, productPath?: string): boolean {
    const action: IWorkflowAction = this.getActionFromPath(workflow, categoryPath);
    let isFulfilled: boolean;

    switch (action.type) {
      case EWorkflowActionType.TESTS_CHECKLIST:
        isFulfilled = this.isTestChecklistFulfilled(action);
        break;
      case EWorkflowActionType.MEASURES_CHECKLIST:
        // get IProductVariance
        isFulfilled = this.isMeasureChecklistFulfilled(this.getProductVariancesByProductVariance(action.measuresChecklists, productPath));
        break;
      default:
        isFulfilled = false;
        break;
    }

    return isFulfilled;
  }

  public static isMeasureChecklistPartiallyFulfilled(variances: IVariance[]): boolean {
    return (
      variances.length > 0 &&
      variances.some((variance): boolean =>
        variance.measuresEntries.some((measuresEntry): boolean =>
          measuresEntry.measureAnswers.some((measureAnswer): boolean => {
            return measureAnswer.measure !== undefined && measureAnswer.measure !== null;
          })
        )
      )
    );
  }

  public static getNextTest(workflow: IWorkflow, categoryPath: string, testPath: string): string[] {
    const action: IWorkflowAction = this.getActionFromPath(workflow, categoryPath);
    let result: number[];

    switch (action.type) {
      case EWorkflowActionType.TESTS_CHECKLIST:
        result = this.getNextTestsChecklistTest(action.testsChecklist.content.elements, this.getPath(testPath));
        break;
      case EWorkflowActionType.MEASURES_CHECKLIST:
        result = this.getNextMeasurementChecklistVariance(action.measuresChecklists[0].variances, this.getPath(testPath));
        break;
      default:
        result = [];
        break;
    }

    return result.map(String);
  }

  public static getNextTestWithoutAnswer(workflow: IWorkflow, categoryPath: string, testPath: string): string[] {
    const action: IWorkflowAction = this.getActionFromPath(workflow, categoryPath);
    let result: number[];
    let nextTestWithoutAnswerPath: string;

    switch (action.type) {
      case EWorkflowActionType.TESTS_CHECKLIST:
        nextTestWithoutAnswerPath = this.getNextTestsChecklistTestWithoutAnswer(action, this.getPath(testPath));

        if (isNil(nextTestWithoutAnswerPath)) {
          // There is no unfilled test after the current one; try again from the beginning
          nextTestWithoutAnswerPath = this.getNextTestsChecklistTestWithoutAnswer(action, []);
        }

        return nextTestWithoutAnswerPath?.split('-') ?? [];
      case EWorkflowActionType.MEASURES_CHECKLIST:
        result = this.getNextVarianceWithoutAnswer(action.measuresChecklists[0].variances, this.getPath(testPath));

        if (result.length === 0) {
          result = this.getPath(testPath);
          result[2] = -1;
          result[3] = -1;
        }

        return result.map(String);
      default:
        return [];
    }
  }

  /**
   * To get the next variance without answer we split the array in two part. The first one consist of all the data
   * after the current variance index. The second one consist of all the data before the current variance index.
   * On each array the current index is excluded. Then, we research the first variance with a null or undefined measure
   * attribute.
   *
   * @param variances is the current variance array
   * @param path is the current user position in the array
   */
  public static getNextVarianceWithoutAnswer(variances: IVariance[], path: number[]): number[] {
    if (0 === variances.length) {
      return path;
    }

    let notFilledVariance = variances.slice(path[2], variances.length).find((ivariance): boolean => {
      const variance = IspVariance.fromIVariance(ivariance);

      return !!variance.measuresEntries.find(
        (measuresSampleAnswer): boolean => !IspMeasuresSampleAnswer.fromIMeasuresSampleAnswer(measuresSampleAnswer).areAllMeasuresDone
      );
    });

    if (notFilledVariance) {
      return notFilledVariance.id.split('-').map((value): number => +value);
    }

    notFilledVariance = variances.slice(0, path[2]).find((ivariance): boolean => {
      const variance = IspVariance.fromIVariance(ivariance);

      return !!variance.measuresEntries.find(
        (measuresSampleAnswer): boolean => !IspMeasuresSampleAnswer.fromIMeasuresSampleAnswer(measuresSampleAnswer).areAllMeasuresDone
      );
    });

    if (notFilledVariance) {
      return notFilledVariance.id.split('-').map((value): number => +value);
    }

    return path.splice(0, path.length - 1);
  }

  // Checks 🧾

  public static saveCheck(workflow: IWorkflow, actionId: string, check: WorkflowCheckType | WorkflowAuditCheckType): IWorkflow {
    const action = this.getActionFromPath(workflow, actionId);

    if (!action.checks) {
      action.checks = [];
      action.checks.push(check);
    } else {
      action.checks = action.checks.map((c): WorkflowCheckType | WorkflowAuditCheckType => {
        if (c.type === check.type) {
          return check;
        }

        return c;
      });
    }

    return workflow;
  }

  // Defect checklist ❌

  public static addDefect(workflow: IWorkflow, defect: IDefectsChecklistDefect): IWorkflow | never {
    if (defect.id) {
      const path: number[] = defect.id.split('-').map((i): number => parseInt(i, 10) - 1);
      const step: IWorkflowStep = workflow.steps[path[0]];
      const action: IWorkflowAction = step.actions[path[1]];
      const category = action.defectsChecklist.categories[path[2]];
      const categoryId = [path[0], path[1], path[2]].map((i): number => i + 1).join('-');

      if (!category) {
        action.defectsChecklist.categories.push({ id: categoryId, name: CUSTOM_QIMA_DEFECT_CHECKLIST, defects: [defect] });
      } else {
        category.defects.push(defect);
      }
    } else {
      throw new Error('Defect id should be set');
    }

    return workflow;
  }

  public static calculateNewDefectId(workflow: IWorkflow, defect: INewDefectInfo): string {
    const path: number[] = defect.stepId.split('-').map((i): number => parseInt(i, 10) - 1);
    const step: IWorkflowStep = workflow.steps[path[0]];
    const action: IWorkflowAction = step.actions[path[1]];
    const { categories } = action.defectsChecklist;
    const lastCategory = categories[categories.length - 1];

    if (lastCategory?.name === CUSTOM_QIMA_DEFECT_CHECKLIST) {
      return [defect.stepId, categories.length, lastCategory.defects.length + 1].join('-');
    }

    return [defect.stepId, categories.length + 1, 1].join('-');
  }

  public static saveDefect(workflow: IWorkflow, defectId: string, updateDefect: IUpdateDefectData): IWorkflow {
    if (defectId) {
      const path: number[] = defectId.split('-').map((i): number => parseInt(i, 10) - 1);
      const step: IWorkflowStep = workflow.steps[path[0]];
      const action: IWorkflowAction = step.actions[path[1]];
      const category = action.defectsChecklist.categories[path[2]];
      const defect = category.defects.find((def): boolean => def.id === defectId);
      const defectValue = defect?.defectsFound?.find(
        (defectFound): boolean =>
          !updateDefect.purchaseOrderProductId || defectFound.purchaseOrderProductId === updateDefect.purchaseOrderProductId
      );

      if (defect) {
        if (updateDefect.classification) {
          defect.classification = updateDefect.classification as DefectClassification;
        }

        // Changed values are in "defectsFound" (and need to be pushed to the array if non-existing)
        if (defectValue) {
          defectValue.quantity = updateDefect.quantity ?? defectValue.quantity;
          defectValue.comment = (updateDefect.comment ?? defectValue.comment) as unknown as InspectionCommentDTO;
        } else {
          defect.defectsFound = [
            ...(defect.defectsFound ?? []),
            {
              quantity: updateDefect.quantity ?? 0,
              comment: updateDefect.comment as unknown as InspectionCommentDTO,
              images: [],
              purchaseOrderProductId: updateDefect.purchaseOrderProductId,
            },
          ];
        }
      }
    }

    return workflow;
  }

  public static getDefectById(workflow: IWorkflow, defectId: string): IDefectsChecklistDefect {
    let defectElement: IDefectsChecklistDefect;

    if (defectId) {
      const path: number[] = defectId.split('-').map((i): number => parseInt(i, 10) - 1);
      const step: IWorkflowStep = workflow.steps[path[0]];
      const action: IWorkflowAction = step.actions[path[1]];
      const category = action.defectsChecklist.categories[path[2]];

      defectElement = category.defects[path[3]];
    }

    return defectElement;
  }

  public static getCategoryByTestId(
    workflow: IWorkflow,
    testId: string,
    type: EWorkflowActionType,
    productIndex?: number
  ): IQpTestsChecklistElement<Answer> | IVariance[] | null {
    let categoryTest: IQpTestsChecklistElement<Answer> | IVariance[] | null;

    if (testId) {
      const path: number[] = WorkflowUtils.getPath(testId);
      const action: IWorkflowAction = WorkflowUtils.getActionFromPath(workflow, testId);

      switch (type) {
        case EWorkflowActionType.TESTS_CHECKLIST:
          categoryTest = action.testsChecklist.content.elements[path[2]];
          break;
        case EWorkflowActionType.MEASURES_CHECKLIST:
          categoryTest = action.measuresChecklists[productIndex].variances;
          break;
        default:
          categoryTest = null;
      }
    }

    return categoryTest;
  }

  public static addMeasureEntry(
    workflow: Readonly<IWorkflow>,
    pathIds: ReadonlyArray<number>,
    sectionName: Readonly<QimaOptionalType<string>>
  ): IWorkflow | never {
    const step: IWorkflowStep = workflow.steps[pathIds[0]];
    const action: IWorkflowAction = step.actions[pathIds[1]];
    const productVariances: IVariance[] = action.measuresChecklists[pathIds[2]].variances;
    const variance: IVariance = productVariances[pathIds[3]];
    const measuresSampleAnswer = ProductMeasurementsService.generateMeasureAnswer(variance, sectionName);

    variance.measuresEntries.push(measuresSampleAnswer);
    variance.numberOfEntries++;

    if (!isNil(sectionName)) {
      const sectionVariances: IVariance[] = action.measuresChecklists[pathIds[2]].sections.find(
        (section: IMeasuresChecklistSection): boolean => section.name === sectionName
      )?.variances;
      const sectionVariance: IVariance = sectionVariances.find((sectionVariance): boolean => sectionVariance.id === variance.id);

      sectionVariance.measuresEntries.push(measuresSampleAnswer);
      sectionVariance.numberOfEntries++;
    }

    return workflow;
  }

  public static getTestFileListByWorkflow(workflow: IWorkflow): Map<string, number> {
    const testFileList = new Map<string, number>();

    workflow.steps.forEach((value): void => {
      value.actions
        .filter((workflowAction): boolean => workflowAction.type === EWorkflowActionType.TESTS_CHECKLIST)
        .forEach((checklistsAction: IWorkflowAction): void => {
          const listOfDoc = WorkflowUtils.getDocumentList(checklistsAction.testsChecklist.content.elements);

          listOfDoc?.forEach((docId: string): void => {
            testFileList.set(docId, checklistsAction.testsChecklist.id);
          });
        });
    });

    return testFileList;
  }

  public static getDefectFileListByWorkflow(workflow: IWorkflow): Map<string, number> {
    const defectFileList = new Map<string, number>();

    workflow.steps.forEach((workflowStep: IWorkflowStep): void =>
      workflowStep.actions
        .filter((workflowAction: IWorkflowAction): boolean => workflowAction.type === EWorkflowActionType.DEFECTS_CHECKLIST)
        .forEach((workflowDefectAction: IWorkflowAction): void => {
          workflowDefectAction.defectsChecklist?.categories?.forEach((defectCategory: IDefectsCategories): void => {
            defectCategory.defects?.forEach((defect: IDefectsChecklistDefect): void => {
              defect.documents?.forEach((docId: string): void => {
                defectFileList.set(docId, workflowDefectAction.defectsChecklist.id);
              });
            });
          });
        })
    );

    return defectFileList;
  }

  public static getDocumentFileListByWorkflow(workflow: IWorkflow): string[] {
    return workflow.steps
      .map((value): string[] =>
        value.actions
          .filter((workflowAction): boolean => workflowAction.type === EWorkflowActionType.DOCUMENT)
          .map((checklistsAction): string => checklistsAction.documentId)
      )
      .reduce((previousValue, currentValue): string[] => previousValue.concat(currentValue, []));
  }

  public static getDocumentList(elements: IQpTestsChecklistElement<Answer>[]): string[] {
    let instructionsDocuments = [];

    elements.forEach((element: QpTestsChecklistElementType): void => {
      if (element.instructionsDocuments) {
        instructionsDocuments = instructionsDocuments.concat(element.instructionsDocuments);
      }

      if (element.elements && element.elements.length > 0) {
        instructionsDocuments = instructionsDocuments.concat(this.getDocumentList(element.elements));
      }
    });

    return instructionsDocuments;
  }

  public static getWorkflowActionsWithType(workflow: IWorkflow, actionType: EWorkflowActionType): IWorkflowAction[] {
    const allWorkflowActions: ReadonlyArray<IWorkflowAction> = workflow.steps
      .map((step: IWorkflowStep): IWorkflowAction[] => step.actions)
      .reduce((actions: IWorkflowAction[], currentValue: IWorkflowAction[]): IWorkflowAction[] => currentValue.concat(actions), []);

    return (
      allWorkflowActions
        .filter((action: IWorkflowAction): boolean => action.type === actionType)
        // sort to have a deterministic ordering => worthy for testing purpose
        .sort((action1: IWorkflowAction, action2: IWorkflowAction): number => (action1.id > action2.id ? 1 : -1))
    );
  }

  public static isTestChecklistElementAnswered(element: IQpTestsChecklistElement<Answer>): boolean {
    if (element.notApplicable) {
      return true;
    }

    if (element.type === EQpQuestionType.TABLE) {
      return !this.isTableElementValueEmpty(element.value as IQpTestsChecklistElementTableValue);
    } else if ([EQpQuestionType.YES_NO, EQpQuestionType.YES_NO_NA].includes(element.type)) {
      return !isNil((element.value as YesNoAnswerModel)?.value);
    } else if (element.type === EQpQuestionType.NUMBER) {
      return !isNil(element.value);
    } else if (element.type === EQpQuestionType.AVERAGE_NUMBER) {
      return !isNil((element.value as AverageNumberValue)?.average);
    }

    return !isEmpty(element.value);
  }

  public static isTestChecklistElementRequirementsFulfilled(element: IQpTestsChecklistElement<Answer>): boolean {
    if (element.notApplicable) {
      return !element.commentsMandatoryWhenNotApplicable || !isEmpty(element.comment?.message?.trim());
    }

    const hasPictureRequirement = element.requirements?.some((req): boolean => req.type === EChecklistRequirementType.PICTURE);
    const isPictureRequirementFulfilled = !hasPictureRequirement || element.images.length > 0;
    const hasScanRequirement = element.requirements?.some((req): boolean => req.type === EChecklistRequirementType.SCAN);
    const isScanRequirementFulfilled = !hasScanRequirement || element.scans.length > 0;
    const hasCommentRequirement: boolean = element.requirements?.some((req): boolean => req.type === EChecklistRequirementType.COMMENT);
    const isCommentRequirementFulfilled = !hasCommentRequirement || !isEmpty(element.comment?.message?.trim());
    const hasAttachmentRequirement = element.requirements?.some((req): boolean => req.type === EChecklistRequirementType.ATTACHMENT);
    const isAttachmentRequirementFulfilled = !hasAttachmentRequirement || element.attachments.length > 0;
    const hasSpotlightRequirement = element.requirements?.some((req): boolean => req.type === EChecklistRequirementType.SPOTLIGHT);
    const hasSpotlightRequirementFulfilled = !hasSpotlightRequirement || element.images.some((image): boolean => image.isSpotlight);

    return (
      isPictureRequirementFulfilled &&
      isScanRequirementFulfilled &&
      isCommentRequirementFulfilled &&
      isAttachmentRequirementFulfilled &&
      hasSpotlightRequirementFulfilled
    );
  }

  public static isTestChecklistElementFulfilled(element: IQpTestsChecklistElement<Answer>): boolean {
    return this.isTestChecklistElementAnswered(element) && this.isTestChecklistElementRequirementsFulfilled(element);
  }

  public static reorderPictures(images: Readonly<IChecklistImageData[]>, newImageOrder: Readonly<string[]>): IChecklistImageData[] {
    return [...images].sort((imageA, imageB): number => newImageOrder.indexOf(imageA.imageId) - newImageOrder.indexOf(imageB.imageId));
  }

  public static getUpdatedPerProductAqlAndSampling(
    inspection: QimaOptionalType<IInspectionConsultation>,
    workflow: QimaOptionalType<IWorkflow>,
    purchaseOrderProductId: QimaOptionalType<number>,
    partialAqlAndSampling: Partial<PerProductAqlAndSamplingDTO>
  ): PerProductAqlAndSamplingDTO[] {
    let perProductAqlAndSampling = workflow?.perProductAqlAndSampling && [...workflow?.perProductAqlAndSampling];

    if (!perProductAqlAndSampling) {
      perProductAqlAndSampling = inspection.products.map(
        (product): PerProductAqlAndSamplingDTO => ({
          productId: product.productId,
          purchaseOrderProductId: product.purchaseOrderProductId,
          aqlDefects: product.aqlDefects as AqlDefectsType,
          sampling: product.samplingSize as IWorkflowSamplingSize,
        })
      );
    }

    const indexOfProductToUpdate = perProductAqlAndSampling?.findIndex(
      (productAqlAndSampling): boolean => productAqlAndSampling.purchaseOrderProductId === purchaseOrderProductId
    );

    if (indexOfProductToUpdate !== -1) {
      perProductAqlAndSampling[indexOfProductToUpdate] = {
        ...perProductAqlAndSampling[indexOfProductToUpdate],
        ...partialAqlAndSampling,
      };
    }

    return perProductAqlAndSampling;
  }

  public static getPerProductAqlAndSamplingTotalLotSize(perProductAqlAndSamplings: PerProductAqlAndSamplingDTO[]): number {
    return perProductAqlAndSamplings.reduce(
      (totalLotSize: number, perProductAqlAndSampling: PerProductAqlAndSamplingDTO): number =>
        totalLotSize + (perProductAqlAndSampling.sampling.lotSize ?? perProductAqlAndSampling.sampling.products[0]?.lotSize ?? 0),
      0
    );
  }

  public static isSimplifiedMeasurementActionCompleted(action: IWorkflowAction): boolean {
    let areMeasuresDone: boolean = false;
    let areProofsDone: boolean = false;

    if (!action.simplifiedMeasurement?.notApplicable) {
      // Check if measurements.measures contains all 3 values that are not null because they are required
      if (
        action?.simplifiedMeasurement?.measurement &&
        qpIsNotNil(action.simplifiedMeasurement.measurement.pointsOfMeasurement) &&
        qpIsNotNil(action.simplifiedMeasurement.measurement.outOfTolerancePointsOfMeasurement) &&
        qpIsNotNil(action.simplifiedMeasurement.measurement.outOfToleranceSamples)
      ) {
        areMeasuresDone = true;
      }

      // check proofs
      if (action.requiredProofs.length === 0) {
        areProofsDone = true;
      } else {
        areProofsDone = action.requiredProofs.every((proof): boolean => {
          if (proof === EWorkflowMeasurementProof.PICTURES) {
            return action.simplifiedMeasurement?.images?.length > 0;
          }

          if (proof === EWorkflowMeasurementProof.ATTACHMENTS) {
            return action.simplifiedMeasurement?.attachments?.length > 0;
          }

          if (proof === EWorkflowMeasurementProof.COMMENTS) {
            return !!action.simplifiedMeasurement?.comment;
          }

          if (proof === EWorkflowMeasurementProof.SPOTLIGHT) {
            return action.simplifiedMeasurement?.images.some((image): boolean => image.isSpotlight);
          }

          return false;
        });
      }
    } else {
      // When simplified measurement is marked as not applicable
      const isCommentRequired = action.isCommentRequiredWhenMarkedAsNA;
      const isCommentProvided = !!action.simplifiedMeasurement.comment;

      return isCommentRequired ? isCommentProvided : true;
    }

    return areMeasuresDone && areProofsDone;
  }

  private static isLastTestsChecklistTest(elements: IQpTestsChecklistElement<Answer>[], path: number[]): boolean {
    const sectionIndex = elements.findIndex((e): boolean => this.getPath(e.id)[2] === path[2]);

    if (sectionIndex === -1) {
      return false;
    }

    if (sectionIndex + 1 < elements.length) {
      return false;
    }

    const testIndex = elements[sectionIndex].elements.findIndex((e): boolean => this.getPath(e.id)[3] === path[3]);

    if (testIndex === -1 || testIndex + 1 < elements[sectionIndex].elements.length) {
      return false;
    }

    return true;
  }

  private static isLastMeasurementChecklist(variances: IVariance[], path: number[]): boolean {
    const sectionIndex = variances.findIndex((variance): boolean => this.getPath(variance.id)[2] === path[2]);

    if (sectionIndex === -1) {
      return false;
    }

    if (sectionIndex + 1 !== variances.length) {
      return false;
    }

    return true;
  }

  private static isTestChecklistFulfilled(checklistAction: IWorkflowAction): boolean {
    const elements: IQpTestsChecklistElement<Answer>[] = checklistAction.testsChecklist.content.elements;

    return elements.every((testsChecklistElement: IQpTestsChecklistElement<Answer>): boolean =>
      testsChecklistElement.elements.every((e: IQpTestsChecklistElement<Answer>): boolean => {
        return this.isTestChecklistElementFulfilled({
          ...e,
          commentsMandatoryWhenNotApplicable: checklistAction.commentsMandatoryWhenNotApplicable,
        });
      })
    );
  }

  private static isMeasureChecklistFulfilled(ivariances: IVariance[]): boolean {
    const variances = ivariances.map((ivariance): IspVariance => IspVariance.fromIVariance(ivariance));

    return variances.every((variance): boolean => variance.areAllMeasuresSampleAnswerDone);
  }

  private static getNextTestsChecklistTest(elements: IQpTestsChecklistElement<Answer>[], path: number[]): number[] {
    const sectionIndex = elements.findIndex((e): boolean => this.getPath(e.id)[2] === path[2]);

    if (sectionIndex === -1) {
      return [];
    }

    const testIndex = elements[sectionIndex].elements.findIndex((e): boolean => this.getPath(e.id)[3] === path[3]);

    if (testIndex === -1) {
      return [];
    }

    if (testIndex + 1 < elements[sectionIndex].elements.length) {
      const result: number[] = path.map((id): number => id + 1);

      result[3] = this.getPath(elements[sectionIndex].elements[testIndex + 1].id)[3] + 1;

      return result;
    }

    if (sectionIndex + 1 < elements.length) {
      const result: number[] = path.map((id): number => id + 1);

      result[2] = this.getPath(elements[sectionIndex + 1].id)[2] + 1;
      result[3] = this.getPath(elements[sectionIndex + 1].elements[0].id)[3] + 1;

      return result;
    }

    return [];
  }

  private static getNextMeasurementChecklistVariance(variances: IVariance[], path: number[]): number[] {
    const sectionIndex = variances.findIndex((variance): boolean => this.getPath(variance.id)[2] === path[2]);

    if (sectionIndex === -1) {
      return [];
    }

    if (sectionIndex + 1 < variances.length) {
      const result = path.map((id): number => id + 1);

      result[2] += 1;

      return result;
    }

    return [];
  }

  private static getNextTestsChecklistTestWithoutAnswer(action: IWorkflowAction, path: number[]): QimaOptionalType<string> {
    const elements: IQpTestsChecklistElement<Answer>[] = action.testsChecklist.content.elements;
    let sectionIndex: number = 0;
    let testIndex: number = -1;

    if (path.length > 0) {
      sectionIndex = elements.findIndex((e): boolean => this.getPath(e.id)[2] === path[2]);
      testIndex = sectionIndex < 0 ? -1 : elements[sectionIndex].elements?.findIndex((e): boolean => this.getPath(e.id)[3] === path[3]);

      if (testIndex < 0) {
        return null;
      }
    }

    testIndex++;
    while (sectionIndex < elements.length) {
      while (testIndex < elements[sectionIndex].elements.length) {
        if (
          !this.isTestChecklistElementFulfilled({
            ...elements[sectionIndex].elements[testIndex],
            commentsMandatoryWhenNotApplicable: action.commentsMandatoryWhenNotApplicable,
          })
        ) {
          return elements[sectionIndex].elements[testIndex].id;
        }

        testIndex++;
      }

      sectionIndex++;
      testIndex = 0;
    }

    return null;
  }

  private static isTableElementValueEmpty(tableValue: IQpTestsChecklistElementTableValue): boolean {
    return (
      tableValue?.rows?.some((row: IQpTestsChecklistElementTableRows): boolean =>
        row.columns?.some((column: QpTestsChecklistElementTableColumnType): boolean => isEmpty(column.value))
      ) ?? false
    );
  }
}
